import React, { useState } from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import NavMobile from "./navMobile"
import { Link } from "gatsby";

export default function HeaderMobile() {
    const [isVisible, setIsVisible] = useState(false);
  return (
    <header class="irn-mobile-header">
        <div class="irn-mobile-header-inner">
            <div class="irn-mobile-header-holder">
                <div class="irn-grid">
                    <div class="irn-vertical-align-containers">
                        <div class="irn-vertical-align-containers">
                            <div className={isVisible ? 'irn-mobile-menu-opener irn-mobile-menu-opened change' : 'irn-mobile-menu-opener'}>
                                <a href="javascript:void(0)" onClick={() => {setIsVisible(!isVisible)}}>
                                    <span class="irn-mobile-menu-icon">
                                        <span aria-hidden="true" class="irn-icon-font-elegant icon_menu">
                                            <div class="bar1"></div>
                                            <div class="bar2"></div>
                                            <div class="bar3"></div>    
                                        </span> 
                                    </span>
                                </a>
                            </div>
                            <div class="irn-position-center">
                                <div class="irn-position-center-inner">
                                    <div class="irn-mobile-logo-wrapper">
                                        <Link to="/">
                                            <img itemprop="image" className="irn-normal-logo" src={'/image/logo.png'} width="208" height="150" alt="light logo" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="irn-position-right">
                                <div class="irn-position-right-inner">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NavMobile isOpen={isVisible} />
    </header>
    )
}