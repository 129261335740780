import React from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Form from "./form"
import Price from "./sections/price"

export default function Footer() {
  return (
      <>
        <Price />
        <footer id="contact" className="wrapper-footer irn-page-footer">
            <div className="irn-footer-top-holder">
                <div className="irn-footer-top-inner irn-full-width">
                    <div className="irn-grid-row irn-footer-top-alignment-left">
                        <div className="irn-column-content irn-grid-col-4">
                            <h4 className="irn-widget-title">Mes coordonnées</h4>
                            <a class="irn-icon-widget-holder irn-icon-has-hover" data-hover-color="#be9d5b" href="tel:0783633787" target="_self">
                                <div>
                                    <span class="irn-icon-border-outer">
                                        <span class="irn-icon-border-inner">
                                        </span>
                                    </span>
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                </div> 
                                <span class="irn-icon-text ">07 83 63 37 87</span> 
                            </a><br/>
                            <a class="irn-icon-widget-holder irn-icon-has-hover" data-hover-color="#be9d5b" href="mailto:contact@isabelle-hypnose-acupression.fr" target="_self">
                                <div>
                                    <span class="irn-icon-border-outer">
                                        <span class="irn-icon-border-inner">
                                        </span>
                                    </span>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div> 
                                <span class="irn-icon-text ">contact@isabelle-hypnose-acupression.fr</span> 
                            </a><br/>
                            <a class="irn-icon-widget-holder irn-icon-has-hover" data-hover-color="#be9d5b" href="https://www.google.com/maps/place/274+Rue+des+Chanterelles,+45160+Olivet/@47.8651519,1.870302,17z/data=!3m1!4b1!4m5!3m4!1s0x47e4e59fad7afb87:0x4a2450f83f9db93f!8m2!3d47.8651519!4d1.8724907" target="_self">
                                <div>
                                    <span class="irn-icon-border-outer">
                                        <span class="irn-icon-border-inner">
                                        </span>
                                    </span>
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                </div> 
                                <span class="irn-icon-text ">274, rue des Chanterelles 45160 OLIVET</span> 
                            </a>
                        </div>
                        <div className="irn-column-content irn-grid-col-8">
                            <h4 className="irn-widget-title">Contactez-moi</h4>
                            <Form />
                        </div>
                    </div>
                </div>
            </div>
            <div class="irn-footer-bottom-holder">
                <div class="irn-footer-bottom-inner irn-full-width">
                    <div class="irn-grid-row ">
                        <div class="irn-grid-col-12">
                            <div id="text-9" class="widget irn-footer-bottom-column-1 widget_text"> 
                                <div class="textwidget">
                                    <p>
                                        <a href="/" target="_blank" rel="noopener noreferrer">
                                            <span >Copyright 2020 @Isabelle Renard </span>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}