import React from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import { Link } from "gatsby";

export default function NavDesktop() {
  return (
    <div class="irn-menu-area">
        <div class="irn-vertical-align-containers">
            <div class="irn-position-center">
                <div class="irn-position-center-inner">
                    <nav class="irn-main-menu irn-drop-down irn-default-nav">
                        <ul id="menu-main-menu" class="clearfix">
                            <li class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children has_sub narrow">
                                <Link activeClassName="irn-active-item current" to="/">
                                    <span class="item_outer">
                                        <span class="item_text">Home</span>
                                    </span>
                                </Link>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                                <Link activeClassName="irn-active-item current" to="/hypnoseTherapeutique">
                                    <span class="item_outer">
                                        <span class="item_text">HYPNOSE THERAPEUTIQUE</span>
                                    </span>
                                </Link>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                                <Link activeClassName="irn-active-item current" to="/acupression">
                                    <span class="item_outer">
                                        <span class="item_text">Acupression</span>
                                    </span>
                                </Link>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                                <Link activeClassName="irn-active-item current" to="/hypnose">
                                    <span class="item_outer">
                                        <span class="item_text">Hypnose</span>
                                    </span>
                                </Link>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                                <a href="#tarifs">
                                    <span class="item_outer">
                                        <span class="item_text">Tarifs</span>
                                    </span>
                                </a>
                            </li>
                            <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                                <a href="#contact">
                                    <span class="item_outer">
                                        <span class="item_text">Contact</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    )
}