import React from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import NavDesktop from './navDesktop'

export default function HeaderDesktop() {
  return (
        <header className="irn-page-header">
            <div className="irn-logo-area">
                <div className="irn-vertical-align-containers">
                    <div className="irn-position-center">
                        <div className="irn-position-center-inner">
                            <div className="irn-logo-wrapper">
                                <a href="/">
                                    <img className="irn-normal-logo" src={'/image/logo.png'} width="208" height="150" alt="light logo" />
                                </a>
                            </div>            
                        </div>                    
                    </div>
                </div>
            </div>
            <NavDesktop />
        </header>
    )
}