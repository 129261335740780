import React, { useState }  from "react"
import axios from "axios";
import { Link } from "gatsby"


const Form = () => {
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null,
      alreadyUse: false
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        setServerState({ alreadyUse: true });
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/9bb239e4-1b01-4b4e-8925-77976ea7fd97",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Votre message a été envoyé !", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    return (
    
        <div>
            {!serverState.alreadyUse && (
            <form onSubmit={handleOnSubmit}>
            <div className="form-group">
                <label for="exampleInputEmail1" required="required">Votre email</label>
                <input type="email" name="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" required="required"/>
            </div> 
            <div className="form-group">
                <label for="exampleInputName">Votre nom</label>
                <input type="text" name="name" className="form-control" id="exampleInputName" required="required"/>
            </div>
            <div className="form-group">
                <label for="exampleFormControlTextarea1">Votre message</label>
                <textarea name="message" className="form-control" id="exampleFormControlTextarea1" rows="7" required="required"></textarea>
            </div>
            <button type="submit" className="btn btn-primary"  disabled={serverState.submitting}>
                Envoyer
            </button>
            </form>
            )}
            {serverState.alreadyUse && (
                <p>Message envoyé !</p>
            )}
            {serverState.status && (
                <p className={!serverState.status.ok ? "errorMsg" : ""}>
                {serverState.status.msg}
                </p>
            )}
        </div>
     
    );
  };
  
  export default Form;