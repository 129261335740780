import React from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import { Link } from "gatsby";

export default function NavMobile(props) {
  return (
    <nav className={props.isOpen ? 'irn-mobile-nav block' : 'irn-mobile-nav none'}>
        <div class="irn-grid">
            <ul id="menu-mobile-menu" class="">
                <li class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children has_sub narrow">
                    <Link activeClassName="irn-active-item current" to="/">
                        <span class="item_outer">
                            <span class="item_text">Home</span>
                        </span>
                    </Link>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                    <Link activeClassName="irn-active-item current" to="/hypnoseTherapeutique">
                        <span class="item_outer">
                            <span class="item_text">Hypnose Thérapeutique</span>
                        </span>
                    </Link>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                    <Link activeClassName="irn-active-item current" to="/acupression">
                        <span class="item_outer">
                            <span class="item_text">Acupression</span>
                        </span>
                    </Link>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                    <Link activeClassName="irn-active-item current" to="/hypnose">
                        <span class="item_outer">
                            <span class="item_text">Hypnose</span>
                        </span>
                    </Link>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                    <a href="#tarifs">
                        <span class="item_outer">
                            <span class="item_text">Tarifs</span>
                        </span>
                    </a>
                </li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children  has_sub narrow">
                    <a href="#contact">
                        <span class="item_outer">
                            <span class="item_text">Contact</span>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
    )
}